import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'market',
    component: () => import(/* webpackChunkName: "article" */ '@/views/Market.vue')
  },
  {
    path: '/:locale?/:market_code?/',
    name: 'start',
    redirect: to => {
      let locale = to.params.locale;
      let market_code = to.params.market_code;
      if (!locale || !market_code)
        return "/";
      return "/" + locale + "/" + market_code + "/article/start";
    }
  },
  {
    path: '/:locale/:market_code/article/:article_slug',
    name: 'article',
    component: () => import(/* webpackChunkName: "supplier" */ '@/views/Article.vue')
  },
  {
    path: '/:locale/:market_code/supplier/:supplier_code',
    name: 'supplier',
    component: () => import(/* webpackChunkName: "supplier" */ '@/views/supplier/SupplierArticle.vue')
  },
  {
    path: '/:locale/:market_code/supplier/:supplier_code/products',
    name: 'supplier-products',
    component: () => import(/* webpackChunkName: "supplier" */ '@/views/supplier/Products.vue')
  },
  {
    path: '/:locale/:market_code/supplier/:supplier_code/:slug',
    name: 'supplier-article',
    component: () => import(/* webpackChunkName: "supplier" */ '@/views/supplier/SupplierArticle.vue')
  },
  {
    path: '/:locale/:market_code/supplier/:supplier_code/products/:product_id',
    name: 'supplier-product',
    props: (route) => ({
      product_id: parseInt(route.params.product_id)
    }),
    component: () => import(/* webpackChunkName: "supplier" */ '@/views/supplier/Product.vue')
  },
  {
    path: '/:locale/:market_code/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active-partial",
  linkExactActiveClass: "active-exact"
})

export default router
