<template>
  <v-nav>
    <template v-slot:left v-if="$route.params.market_code">
      <v-nav-item :columns="2" ref="leftMenu">
        {{$tc("supplier.supplier", 10)}}
        <template v-slot:content>
          <v-dropdown-content
            variant="columns"
            :columns="2"
            :dropdownTitle="$tc('supplier.supplier', 10)"
          >
            <ul>
              <li v-for="(supplier, index) in getCurrentSuppliers" :key="index">
                <router-link
                  @click.native="navigate()"
                  :to="{ name: 'supplier', params: { supplier_code: supplier.code }}"
                >{{ supplier.name }}</router-link>
              </li>
            </ul>
          </v-dropdown-content>
        </template>
      </v-nav-item>
    </template>
    <template v-slot:menu v-if="$route.params.market_code">
      <div class="textmenu">{{$t("nav.menu")}}</div>
      <div class="iconmenu">
        <v-icon icon="close" :ratio="0.5"></v-icon>
      </div>
    </template>
    <template v-slot:logo>
      <router-link class="logo" :to="{ name: 'start', params: $route.params }">
        <img :src="require('vcc-ui-vue/assets/images/volvo-logo.png')" width="51" height="51" />
      </router-link>
    </template>
    <template v-slot:right v-if="$route.params.market_code" ref="rightMenu">
      <v-nav-item>
        <router-link
          @click.native="navigate()"
          :to="{ name: 'article', params: { article_slug: 'contact' } }"
        >{{$t("nav.contact")}}</router-link>
      </v-nav-item>
      <v-nav-item>
        {{$t("market.change")}}
        <template v-slot:content>
          <v-dropdown-content variant="columns" :dropdownTitle="$t('market.change')">
            <ul>
              <li v-for="(market, index) in markets" :key="index">
                <router-link
                  @click.native="navigate()"
                  :to="{ name: 'start', params: { ...$route.params, market_code: market.code }}"
                >{{ market.name }}</router-link>
              </li>
            </ul>
          </v-dropdown-content>
        </template>
      </v-nav-item>
    </template>
    <template v-slot:search v-if="$route.params.market_code">
      <router-link :to="{ name: 'search' }">
        <v-icon class="searchmenuicon" icon="search" :ratio="0.6" />
      </router-link>
    </template>
  </v-nav>
</template>

<script>
import { api } from "@/plugins/api";
import marketMixin from "@/mixins/market.js";
import supplierMixin from "@/mixins/supplier.js";

export default {
  name: "navigation-primary",
  mixins: [marketMixin, supplierMixin],
  computed: {
    getCurrentSuppliers() {
      return this.currentSuppliers;
    },
  },
  methods: {
    navigate() {
      [this.$refs.leftMenu, this.$refs.rightMenu]
        .filter(e => e)
        .forEach(e => {
          e.$emit("active", false);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.v-nav {
  .logo {
    align-content: center;
    align-items: center;
    display: flex;
  }
}

.v-nav {
  .menu-nav {
    .textmenu {
      display: block;
    }
    .iconmenu {
      display: none;
    }
  }
  &.menuIsOpen {
    .textmenu {
      display: none;
    }
    .iconmenu {
      display: block;
    }
  }
}
</style>
