import Vue from 'vue'
import Vuex from 'vuex'

import users from "@/plugins/store/users.js";
import markets from "@/plugins/store/markets.js";
import suppliers from "@/plugins/store/suppliers.js";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    markets: markets,
    suppliers: suppliers,
    users: users
  }
})
