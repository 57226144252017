<template>
  <footer class="v-footer theme-dark">
    <div class="footer-row footer-language-row" v-if="market">
      <p>
        <v-icon icon="globe" :ratio="0.6" />
        {{market}} | {{locale}}
      </p>
      <router-link :to="{ name: 'market' }">
        {{ $t("footer.changeLocation")}}
        <v-icon icon="angle-right" :ratio="0.6" />
      </router-link>
    </div>

    <div class="footer-row">{{ $t("footer.copyright", { year: new Date().getFullYear() }) }}</div>

    <nav class="footer-row footer-list" v-if="market">
      <router-link
        :to="{ name: 'article', params: { article_slug: article.slug } }"
        v-for="(article, index) in articles"
        :key="index"
      >{{article.title}}</router-link>
    </nav>

    <div class="footer-row">
      <router-link :to="{ name: 'market' }">
        <img :src="require('vcc-ui-vue/assets/images/volvo-wordmark.svg')" width="194" height="15" />
      </router-link>
    </div>

    <div class="footer-row">
      <v-button
        variant="outline"
        intent="primary"
        icon="angle-up"
        @button-click="scrollToTop()"
      >{{ $t("footer.scrollToTop")}}</v-button>
    </div>
  </footer>
</template>

<script>
import { api } from "@/plugins/api";
import locales from "@/plugins/localization/locales";
import marketMixin from "@/mixins/market.js";

export default {
  name: "v-footer",
  mixins: [marketMixin],
  data() {
    return {
      articles: []
    };
  },
  computed: {
    market() {
      return this.markets.find(
        market => market.code === this.$route.params.market_code
      )?.name;
    },
    locale() {
      return locales.find(locale => locale.code === this.$locale).name;
    }
  },
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  },
  created() {
    api.article.listBySections(["footer"]).then(articles => {
      this.articles = articles;
    });
  }
};
</script>

<style lang="scss">
.v-footer {
  padding: 50px 0;
  color: #fff;
  background-color: #161618;
  text-align: center;

  a {
    color: #fff;
    &:hover {
      color: #ddd;
    }
  }

  .footer-row {
    margin: 40px 0;
  }

  .footer-language-row {
    display: flex;
    align-items: center;
    flex-direction: column;
    .v-icon {
      margin: 0 5px;
    }
  }

  .footer-list {
    a {
      &::after {
        content: " | ";
        display: inline;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
