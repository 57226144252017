import { api } from "@/plugins/api";

const state = {
    suppliers: []
}

const getters = {
    suppliers(state) {
        return state.suppliers;
    }
}

const actions = {
    async fetchSuppliers({ commit }) {
        if (state.suppliers.length > 0)
            return;
        const suppliers = await api.supplier.list();
        commit('SET_SUPPLIERS', suppliers);
    }
}

const mutations = {
    SET_SUPPLIERS(state, payload) {
        state.suppliers = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
