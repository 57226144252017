import { mapGetters } from "vuex";
import marketMixin from "@/mixins/market.js";

export default {
    mixins: [marketMixin],
    computed: {
        ...mapGetters({
            suppliers: "suppliers/suppliers"
        }),
        currentSuppliers() {
          // TODO: Is this really correct?
            return this.suppliers.filter(supplier => supplier.markets.some(market => market.market_id === this.currentMarketId));
        },
        currentSupplier() {
            return this.suppliers.find(supplier => supplier.code === this.$route.params.supplier_code);
        }
    }
};
