import { api } from "@/plugins/api";

const state = {
  me: {},
  isAdmin: false
}

const getters = {
  me(state) {
    return state.me;
  },
  isAdmin(state) {
    return state.isAdmin;
  }
}

const actions = {
  async fetchMe({ commit }) {
    const me = await api.user.me();
    commit('SET_ME', me);
  }
}

const mutations = {
  SET_ME(state, payload) {
    state.me = payload;
  },
  SET_ISADMIN(state, payload) {
    state.isAdmin = payload;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
