<template>
  <v-sub-nav>
    <template v-slot:left>
        <router-link :to="routerLink"  v-slot="{ href, route, navigate, isActive, isExactActive }">
          <v-nav-item theme="dark"  :class="[isActive && 'active-partial', isExactActive && 'active-exact']">
            <a :href="href" @click="navigate">
              <v-icon class="return-icon" v-if="returnRoute" icon="angle-left" :ratio="0.5"></v-icon>
              <span class="return-text" v-if="returnRoute" v-html="$t('subnav.backTo', { name: title })"></span>
              <span class="return-title" v-if="!returnRoute" >{{title}}</span>
            </a>
          </v-nav-item>
        </router-link>
    </template>
    <template v-slot:right>
      <router-link :to="link.to"  v-slot="{ href, route, navigate, isActive, isExactActive }" v-for="(link, index) in links" :key="index">
        <v-nav-item theme="dark"  :class="[isActive && 'active-partial', isExactActive && 'active-exact']">
            <a :href="href" @click="navigate">
              {{ link.title }}
          </a>
        </v-nav-item>
      </router-link>
    </template>
  </v-sub-nav>
</template>

<script>
import supplierMixin from "@/mixins/supplier.js";
import { api } from "@/plugins/api";

export default {
  name: "v-navigation-supplier",
  mixins: [supplierMixin],
  props: {
    returnRoute: { type: Object },
    title: { type: String }
  },
  data() {
    return {
      supplierArticles: []
    };
  },
  computed: {
    routerLink() {
      return this.returnRoute || { name: "supplier" };
    },
    links() {
      let links = this.supplierArticles.map(supplierArticle => {
        return {
          to: {
            name: "supplier-article",
            params: {
              slug: supplierArticle.slug
            }
          },
          title: supplierArticle.title
        };
      });

      // Insert products link last
      links.push({
        to: { name: "supplier-products" },
        title: this.$tc("product.product", 10)
      });

      return links;
    }
  },
  methods: {
    getArticles() {
      if (!this.currentSupplier) return;
      api.supplierArticle
        .filter({
          supplier_id: this.currentSupplier.id
        })
        .then(supplierArticles => {
          this.supplierArticles = supplierArticles;
        });
    }
  },
  mounted() {
    this.getArticles();
  },
  watch: {
    currentSupplier: {
      handler() {
        this.getArticles();
      }
    }
  }
};
</script>
<style lang="scss">
  @import "vcc-ui-vue/design/variables.scss";
  .v-icon.return-icon, .return-text {
      display: inline !important;
    }
  .return-title{
    display: inline;
  }
  @media screen and (max-width: $grid-tablet-breakpoint){
    .v-icon.return-icon {
      display: none !important;
    }
    .return-title{
      display: inline;
    }
  }
</style>
