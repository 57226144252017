export default {
  sv: {
    market: {
      region: "Region",
      language: "Språk",
      buttonCta: "Gå vidare",
      change: "Byt marknad"
    },
    nav: {
      menu: "Meny",
      contact: "Kontakta oss"
    },
    subnav: {
      backTo: "Tillbaka till <b>{name}</b>"
    },
    siteSearch: {
      inputPlaceholder: "Vad letar du efter?",
      resultsFound: "Inga resultat hittades | {count} resultat hittades |  {count} resultat hittades"
    },
    article: {
      article: "Artikel"
    },
    supplier: {
      supplier: "Leverantör | Leverantörer",
      productsButtonCta: "Se våra produkter"
    },
    product: {
      product: "Produkt | Produkter",
      surface: "Yta | Ytor",
      line: "Produktlinje | Produktlinjer",
      type: "Produkttyp",
      files: "Filer",
      related: "Relaterade produkter",
      search: {
        heading: "Produkter och innovation",
        introduction: "Lorem ipsum.",
        inputPlaceholder: "Sök produkt",
        filterWaterBased: "Visa enbart vattenbaserade produkter",
        buttonClearFiltersCta: "Rensa alla",
        noResults: "Inga Volvo godkända produkter hittades.",
        noResultsWithFilters: "Inga Volvo godkända produkter hittades med dina nuvarande sökfilter."
      },
      solvent: {
        solvent: "Lösningsmedel",
        waterBased: "Vattenbaserad"
      }
    },
    footer: {
      copyright: "Copyright © {year} Volvo Car Corporation (eller dess dotterbolag/licensgivare).",
      scrollToTop: "Till toppen på sidan",
      changeLocation: "Byt region"
    },
    common: {
      readmore: "Läs mer"
    }
  }
}
