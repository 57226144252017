import Vue from 'vue'
import VueI18n from 'vue-i18n'
import getUserLocale from 'get-user-locale'
import messages from '@/plugins/localization/messages'
import locales from '@/plugins/localization/locales'

Vue.use(VueI18n)

const fallbackLocale = 'en';

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: getValidUserLocale(getUserLocale(), locales),
  fallbackLocale: fallbackLocale,
  messages
});

function getValidUserLocale(userLocale, localesAvailable) {
  // Try match IETF language tag: "en-US"
  let locale = localesAvailable.find(l => { return l.code === userLocale; });
  if (locale) {
    return locale.code;
  }

  // Otherwise try match two-letter: "en"
  locale = localesAvailable.find(l => { return l.code === userLocale.slice(0, 2); });
  if (locale) {
    return locale.code;
  }

  // No matches? Use fallback.
  return fallbackLocale;
}

export default i18n;
