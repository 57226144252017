// VIDA languages
export default [
  /*
  {
    code: "zh-Hans",
    name: "Chinese (Simplified)"
  },
  {
    code: "zh-Hant",
    name: "Chinese (Traditional)"
  },
  {
    code: "nl",
    name: "Dutch"
  },*/
  {
    code: "en",
    name: "English"
  }/*,
  {
    code: "fi",
    name: "Finnish"
  },
  {
    code: "fr",
    name: "French"
  },
  {
    code: "de",
    name: "German"
  },
  {
    code: "it",
    name: "Italian"
  },
  {
    code: "jp",
    name: "Japanese"
  },
  {
    code: "ko",
    name: "Korean"
  },
  {
    code: "pt",
    name: "Portuguese"
  },
  {
    code: "ru",
    name: "Russian"
  },
  {
    code: "es",
    name: "Spanish"
  },
  {
    code: "sv",
    name: "Swedish"
  },
  {
    code: "th",
    name: "Thai"
  },
  {
    code: "tr",
    name: "Turkish"
  }*/
];
