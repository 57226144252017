<template>
  <div id="app">
    <NavigationPrimary></NavigationPrimary>
    <div class="app-content">
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavigationPrimary from "@/components/NavigationPrimary.vue";
import NavigationSupplier from "@/components/NavigationSupplier.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "app",
  components: {
    NavigationPrimary,
    NavigationSupplier,
    Footer
  }
};
</script>

<style lang="scss">
@import "@/design/init.scss";

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  section,
  footer {
    flex: 0 0 auto;
  }
}
</style>
