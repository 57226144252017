/* eslint-disable */
import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import 'whatwg-fetch'
import router from '@/plugins/router'
import store from '@/plugins/store'
import analytics from '@/plugins/analytics'
import i18n from '@/plugins/localization/i18n'

// Components
import { Button, Checkbox, DropdownContent, Icon, LoadingBar, Nav, NavItem, SubNav, SelectInput, Spinner, TabNav, TextArea, TextInput, Toggle } from "vcc-ui-vue/components/index.js";
Vue.component('v-button', Button);
Vue.component('v-checkbox', Checkbox);
Vue.component('v-dropdown-content', DropdownContent);
Vue.component('v-icon', Icon);
Vue.component('v-loading-bar', LoadingBar);
Vue.component('v-nav', Nav);
Vue.component('v-nav-item', NavItem);
Vue.component('v-sub-nav', SubNav);
Vue.component('v-select-input', SelectInput);
Vue.component('v-spinner', Spinner);
Vue.component('v-tab-nav', TabNav);
Vue.component('v-text-area', TextArea);
Vue.component('v-text-input', TextInput);
Vue.component('v-toggle', Toggle);

Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, '$locale', {
  get: function () {
    return i18n.locale
  },
  set: function (locale) {
    i18n.locale = locale
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    this.$store.dispatch("markets/fetchMarkets");
    this.$store.dispatch("suppliers/fetchSuppliers");
  }
}).$mount('#app');

// Route guards
router.beforeResolve((to, from, next) => {
  i18n.locale = to.params.locale || i18n.locale || i18n.fallbackLocale;
  next();
});
