import i18n from '@/plugins/localization/i18n'
import "core-js/features/string/starts-with"
import "core-js/features/string/ends-with"

const setupClient = () => {
  let client = {
    url: "https://pia-directus.stendahls.dev/",
    storage: window.localStorage,
    getCollections: async () => {
      return await fetch(client.url + "collections");
    },
    getMe: async () => {
      return await fetch(client.url + "users/me");
    },
    getItems: async (item, fields = [], filters = []) => {
      let fetchURL = client.url + "items/" + item;
      if (fields.length > 0 || filters.length > 0) {
        fetchURL += "?";
        if (fields.length > 0) {
          fetchURL += "&fields=" + fields.join(",");
        }
        if (filters.length > 0) {
          fetchURL += filters.map(filter => {
            return filter !== "" ? "&filter" + filter : "";
          }).join("");
        }
      }
      return await fetch(fetchURL).then(function (response) {
        return response.json();
      }).then(function (data) {
        return data.data;
      });
    },

    queryItems: async (collection, fields, filter) => {
      const fetchURL = client.url + "graphql/";

      const options = {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          query: "{ "+collection+" "+filter+" "+fields+" }",
        }),
      };

      return await fetch(fetchURL, options).then(function (response) {
        return response.json();
      }).then(function (data) {
        if (!data || !data.data) {
          return [];
        }
        return data.data[collection];
      });
    },
  };
  return client;
};

const cache = {
  get(key) {
    return this.data[key];
  },
  set(key, data) {
    this.data[key] = data;
  },
  remove(key) {
    delete this.data[key];
  },
  clear() {
    this.data = [];
  },
  data: []
};

// Contextual data mapping
const map = {
  markets(items) {
    return map.translations(items);
  },
  suppliers(items) {
    return map.translations(items);
  },
  lines(items) {
    return map.translations(items);
  },
  surfaces(items) {
    return map.translations(items);
  },
  articles(items) {
    return map.translations(items);
  },
  supplierArticles(items) {
    return map.translations(items);
  },
  products(items) {
    items = map.translations(items);
    if (items === undefined) {
      return [];
    }
    items.forEach(item => {
      // Copy props from fallback
      if (i18n.locale !== i18n.fallbackLocale) {

        let props = item.translations.find(
          x => x.language === i18n.locale
        );
        let fallbackProps = item.translations.find(
          x => x.language === i18n.fallbackLocale
        );

        // If current locale has no files, use english files if exists
        if (Array.isArray(props?.files) && props?.files.length === 0 && Array.isArray(fallbackProps?.files) && fallbackProps?.files.length > 0) {
          item.files = fallbackProps.files;
        }
      }
    });
    return items;
  },
  translations(items) {

    const copyTranslations = (item) => {
      // Try current locale
      let props = item.translations.find(
        x => x.language === i18n.locale
      );

      // Otherwise use fallback
      if (!props) {
        props = item.translations.find(
          x => x.language === i18n.fallbackLocale
        );
      }

      // Don't copy translations references
      if (props) {
        delete props.id;
      }

      // Flatten props
      item = Object.assign(item, props);
    };

    // Recursive object translations copy
    const rec = (obj) => {
      if (Array.isArray(obj)) {
        obj.forEach(item => {
          rec(item);
        });
      } else if (obj && typeof obj === "object") {
        Object.entries(obj).filter(item => item[1]).forEach(([key, value]) => {
          if (key === 'translations') {
            copyTranslations(obj);
          } else {
            rec(obj[key]);
          }
        });
      }
      return obj;
    };

    return rec(items);
  }
};

const api = {
  map: map,
  client: setupClient(),
  urls: {
    assets() {
      return api.client.url+"assets/";
    }
  },
  user: {
    async me() {
      return await api.client.getMe();
    }
  },
  collection: {
    async list() {
      return await api.client.getCollections();
    }
  },
  product: {
    async getById(id) {
      return api.map.products(await api.client.getItems("product",
        ["*", "translations.*", "translations.files.directus_files_id.*", "markets.*", "photo.id", "supplier.*", "line.*", "surfaces.surface_id.*", "solvent.*", "sku.*"],
        ["[status][_eq]=published", "[id][_eq]=" + id]))[0];
    },
    async list() {
      return api.map.products(await api.client.getItems("product",
        ["*", "translations.*"],
        ["[status][_eq]=published"]));
    },
    async filter(filterDto) {
      return api.map.products(await api.client.getItems("product",
        ["*", "translations.*", "markets.*", "photo.id", "supplier.*", "line.*", "surfaces.*", "solvent.*", "sku.*"],
        ["[status][_eq]=published",
          "[markets][market_id][_eq]="+filterDto.market_id,
          filterDto.supplier_id ? "[supplier][_eq]="+filterDto.supplier_id : "",
          filterDto.lines && filterDto.lines.length > 0 ? "["+filterDto.line+"][_in]="+filterDto.lines : "",
          filterDto.surfaces && filterDto.surfaces.length > 0 ? "[surfaces][surface_id][_in]="+filterDto.surfaces : "",
          filterDto.solvents ? "[solvent][_in]="+filterDto.solvents: "",
          filterDto.query ? "[name][_contains]="+filterDto.query : ""]));
    },
    async search(filterDto) {
      const fields = `{
        id,
        line {
          id
        },
        markets {
          market_id {
            id,
          },
        },
        name,
        photo {
          id,
        },
        supplier {
          code,
        },
        surfaces {
          surface_id {
            id,
          },
        },
        translations {
          name,
          description,
        },
      }`;

      let filter = "(filter: { _and: [{ status: { _eq: \"published\" } },";
      filter += filterDto.lines && filterDto.lines.length > 0 ? "{ line: { id: { _in: ["+filterDto.lines.join(",")+"] } } }," : "";
      filter += filterDto.market_id ? "{ markets: { market_id: { id: { _eq: "+filterDto.market_id+" } } } }," : "";
      filter += filterDto.query ? "{ _or: [{ translations: { name: { _contains: \""+filterDto.query+"\" } } }, { translations: { description: { _contains: \""+filterDto.query+"\" } } }] }," : "";
      filter += filterDto.solvents && filterDto.solvents.length > 0 ? "{ solvent: { _in: [\""+filterDto.solvents.join("\",\"")+"\"] } }," : "";
      filter += filterDto.supplier_id ? "{ supplier: { id: { _eq: "+filterDto.supplier_id+" } } }," : "";
      filter += filterDto.surfaces && filterDto.surfaces.length > 0 ? "{ surfaces: { surface_id: { id: { _in: ["+filterDto.surfaces.join(",")+"] } } } }," : "";
      filter += "] })";

      return api.map.products(await api.client.queryItems("product", fields, filter));
    }
  },
  supplier: {
    async list() {
      return api.map.suppliers(await api.client.getItems("supplier",
        ["*", "logo.id", "markets.*"],
        ["[status][_eq]=published"]));
    },
    async filter(filterDto) {
      return api.map.suppliers(await api.client.getItems("supplier",
        ["*", "logo.id", "markets.*"],
        ["[status][_eq]=published",
          "[markets][market_id]="+filterDto.market_id,
          filterDto.query ? "[name][_contains]="+filterDto.query : "" ]));
    },
    async search(filterDto) {
      const fields = `{
            supplier {
              name,
              code,
              logo {
                id
              },
              markets {
                market_id {
                  id
                },
              },
            }
          }`;

      let filter = "(filter: { _and: [{ status: { _eq: \"published\" } },";
      filter += filterDto.market_id ? "{ markets: { market_id: { id: { _eq: "+filterDto.market_id+" } } } }," : "";
      filter += filterDto.query ? "{ name: { _contains: \""+filterDto.query+"\" } }," : "";
      filter += "] })";
      return api.map.articles(await api.client.queryItems("supplier", fields, filter));
    }
  },
  supplierArticle: {
    async getBySlug(slug) {
      return api.map.supplierArticles(await api.client.getItems("supplier_article",
        ["*","hero_image.id"],
        ["[status][_eq]=published", "[slug][_eq]=" + slug]))[0];
    },
    async filter(filterDto) {
      return api.map.supplierArticles(await api.client.getItems("supplier_article",
        ["*", "hero_image.id", "translations.language", "translations.title", "translations.body"],
        ["[status][_eq]=published",
          "[supplier][_eq]="+filterDto.supplier_id,
          filterDto.slug ? "[slug][_eq]="+filterDto.slug : ""]));
    }

  },
  article: {
    async getBySlug(slug) {
      return api.map.articles(await api.client.getItems("article",
        ["*", "hero_image.id", "translations.language", "translations.title", "translations.body", "translations.herotext"],
        ["[status][_eq]=published", "[slug][_eq]=" + slug]))[0];
    },
    async listBySections(sections) {
      return api.map.articles(await api.client.getItems("article",
        ["*", "translations.language", "translations.title", "translations.body"],
        ["[status][_eq]=published", "[section][_in]=" + sections.join(",")]));
    },
    async list() {
      return api.map.articles(await api.client.getItems("article",
        ["*", "translations.language", "translations.title", "translations.body"],
        ["[status][_eq]=published"]));
    },
    async filter(filterDto) {
      return api.map.articles(await api.client.getItems("article",
        ["*", "hero_image.id", "translations.language", "translations.title", "translations.body"],
        ["[status][_eq]=published",
          filterDto.query ? ["[translations][title][_contains]="+filterDto.query] : ""]));
    },
    async search(filterDto) {
      const fields = `{
            id,
            hero_image {
              id,
            },
            slug,
            translations {
              language,
              title,
              body,
            },
          }`;

      let filter = "(filter: { _and: [{ status: { _eq: \"published\" } },";
      filter += filterDto.query ? "{ _or: [{ translations: { title: { _contains: \""+filterDto.query+"\" } } }, { translations: { herotext: { _contains: \""+filterDto.query+"\" } } }, { translations: { body: { _contains: \""+filterDto.query+"\" } } }] }," : "";
      filter += "] })";
      return api.map.articles(await api.client.queryItems("article", fields, filter));
    }
  },
  market: {
    async list() {
      return api.map.markets(await api.client.getItems("market"));
    }
  },
  surface: {
    async list() {
      return api.map.surfaces(await api.client.getItems("surface"));
    }
  },
  line: {
    async list() {
      return api.map.lines(await api.client.getItems("line"));
    }
  }
};

export {api, map, cache};
