export default {
  en: {
    market: {
      region: "Region",
      language: "Language",
      buttonCta: "Go",
      change: "Change market"
    },
    nav: {
      menu: "Menu",
      contact: "Contact us"
    },
    subnav: {
      backTo: "Back to <b>{name}</b>"
    },
    siteSearch: {
      inputPlaceholder: "What are you looking for?",
      resultsFound: "No results found | {count} result found |  {count} results found"
    },
    article: {
      article: "Article"
    },
    supplier: {
      supplier: "Supplier | Suppliers",
      productsButtonCta: "View our products"
    },
    product: {
      product: "Product | Products",
      surface: "Surface | Surfaces",
      line: "Product line | Product lines",
      type: "Product type",
      files: "Files",
      related: "Related products",
      search: {
        heading: "Products",
        introduction: "Paint repairs on Volvo cars are performed using the highest quality products from trusted suppliers and state-of-art repair methods. The products presented here have all been tested and approved by Volvo Cars and meet our strict standards regarding quality, safety and sustainability.",
        inputPlaceholder: "Search product",
        filterWaterBased: "Show only waterborne products",
        buttonClearFiltersCta: "Clear all",
        noResults: "No Volvo Cars approved products found.",
        noResultsWithFilters: "No Volvo Cars approved products found with your current search filters."
      },
      solvent: {
        solvent: "Solvent",
        waterBased: "Waterborne"
      }
    },
    footer: {
      copyright: "Copyright © {year} Volvo Car Corporation (or its affiliates or licensors).",
      scrollToTop: "To the top",
      changeLocation: "Change location"
    },
    common: {
      readmore: "Read more"
    }
  }
}