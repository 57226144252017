import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
          markets: "markets/markets"
        }),
        currentMarket() {
            return this.markets.find(m => m.code === this.$route.params.market_code);
        },
        currentMarketId() {
            return this.currentMarket?.id;
        },
        currentMarketCode() {
            return this.currentMarket?.code;
        }
    }
};