import { api } from "@/plugins/api";

const state = {
    markets: []
}

const getters = {
    markets(state) {
        return state.markets;
    }
}

const actions = {
    async fetchMarkets({ commit }) {
        if (state.markets.length > 0)
            return;
        const markets = await api.market.list();
        commit('SET_MARKETS', markets);
    }
}

const mutations = {
    SET_MARKETS(state, payload) {
        state.markets = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
